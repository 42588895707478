.MuiTooltip-popper .MuiTooltip-tooltip {
  background-color: #fff;
  color: #000;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  max-height: 405px;
  width: auto;
  max-width: 400px;
}

.MuiTooltip-popper .MuiTooltip-tooltip pre {
  overflow: auto;
  max-height: 400px;
  width: auto;
  max-width: 400px;
}

.MuiTooltip-popper .MuiTooltip-tooltip .tooltip-plain {
  padding: 5px;
  line-height: 1.5;
}