.filter-controls {
  margin: 10px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-self: stretch;
}

.filter-controls .cell {
  flex: 3 1 auto;
}

.filter-controls .cell.button-cell {
  flex: 2 1 auto;
  align-content: flex-end;
}

.filter-controls .cell.button-cell .filter-button {  
  color: #fff;
  background-color: #1176B5;
  border-radius: 4px;
  box-shadow: 0 0.4px 0.6px 0 rgba(0,0,0,0.37), 0 0.1px 0 0.4px #0A649D;
  height: 40px;
  min-width: 96px;
  max-width: 96px;
  margin: 0;
  text-transform: capitalize;
}

.custom-tooltip {
  background-color: #fff;
  padding: 5px;
}

/* .ag-popup {
  margin: auto;
  width: auto;
}

.breakout {
  margin:1em -100%;
  margin:1em calc(50% - 50vw);
} */