.row-controls {
  overflow: hidden;
}

.feed-button {
  padding: 0;
}

.delete-icon {
  color: #D02F2F;
}